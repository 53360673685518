<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        this.type === 'view'
          ? '供应商管理查看'
          : this.type === 'add'
          ? '供应商管理新增'
          : '供应商管理编辑'
      "
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <form-layout
      ref="formLayout"
      :column="formColumn"
      :dataForm="dataForm"
    ></form-layout>
    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane :label="'供应商联系信息'" name="conInfo">
        <grid-head-layout
          :grid-head-btn="conInfoHeadBtnOptions"
          @head-remove="conInfoHeadRemove"
          @head-add-tabs="conInfoTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="conInfoCrud"
          class="serviceType"
          :grid-row-btn="conInfoRowBtn"
          :table-options="conInfoTableOption"
          :table-data="conInfoData"
          :table-loading="conInfoTableLoading"
          :data-total="conInfoPageTotal"
          :page="conInfoPage"
          @gird-handle-select-click="conInfoSelectionChange"
          @page-current-change="conInfoOnLoad"
          @page-size-change="conInfoOnLoad"
          @page-refresh-change="conInfoOnLoad"
          @row-view="conInfoEdit"
          @row-remove="conInfoRemove"
          @row-edit="conInfoEdit"
        >
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane :label="'供应商资质信息'" name="qualInfo">
        <grid-head-layout
          :grid-head-btn="qualInfoHeadBtnOptions"
          @head-remove="qualInfoHeadRemove"
          @head-add-tabs="qualInfoTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="qualInfoCrud"
          class="serviceType"
          :grid-row-btn="qualInfoRowBtn"
          :table-options="qualInfoTableOption"
          :table-data="qualInfoData"
          :table-loading="qualInfoTableLoading"
          :data-total="qualInfoPageTotal"
          :page="qualInfoPage"
          @gird-handle-select-click="qualInfoSelectionChange"
          @page-current-change="qualInfoOnLoad"
          @page-size-change="qualInfoOnLoad"
          @page-refresh-change="qualInfoOnLoad"
          @row-view="qualInfoEdit"
          @row-remove="qualInfoRemove"
          @row-edit="qualInfoEdit"
        >
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane :label="'供应商财务情况'" name="finInfo">
        <grid-head-layout
          :grid-head-btn="finInfoHeadBtnOptions"
          @head-remove="finInfoHeadRemove"
          @head-add-tabs="finInfoTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="finInfoCrud"
          class="serviceType"
          :grid-row-btn="finInfoRowBtn"
          :table-options="finInfoTableOption"
          :table-data="finInfoData"
          :table-loading="finInfoTableLoading"
          :data-total="finInfoPageTotal"
          :page="finInfoPage"
          @gird-handle-select-click="finInfoSelectionChange"
          @page-current-change="finInfoOnLoad"
          @page-size-change="finInfoOnLoad"
          @page-refresh-change="finInfoOnLoad"
          @row-view="finInfoEdit"
          @row-remove="finInfoRemove"
          @row-edit="finInfoEdit"
        >
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane :label="'供应商收款信息'" name="bankInfo">
        <grid-head-layout
          :grid-head-btn="bankInfoHeadBtnOptions"
          @head-remove="bankInfoHeadRemove"
          @head-add-tabs="bankInfoTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="bankInfoCrud"
          class="serviceType"
          :grid-row-btn="bankInfoRowBtn"
          :table-options="bankInfoTableOption"
          :table-data="bankInfoData"
          :table-loading="bankInfoTableLoading"
          :data-total="bankInfoPageTotal"
          :page="bankInfoPage"
          @gird-handle-select-click="bankInfoSelectionChange"
          @page-current-change="bankInfoOnLoad"
          @page-size-change="bankInfoOnLoad"
          @page-refresh-change="bankInfoOnLoad"
          @row-view="bankInfoEdit"
          @row-remove="bankInfoRemove"
          @row-edit="bankInfoEdit"
        >
        </grid-layout>
      </el-tab-pane>
      <el-tab-pane :label="'供应商产品目录'" name="proInfo">
        <grid-head-layout
          :grid-head-btn="proInfoHeadBtnOptions"
          @head-remove="proInfoHeadRemove"
          @head-add-tabs="proInfoTableAdd"
        ></grid-head-layout>
        <grid-layout
          ref="proInfoCrud"
          class="serviceType"
          :grid-row-btn="proInfoRowBtn"
          :table-options="proInfoTableOption"
          :table-data="proInfoData"
          :table-loading="proInfoTableLoading"
          :data-total="proInfoPageTotal"
          :page="proInfoPage"
          @gird-handle-select-click="proInfoSelectionChange"
          @page-current-change="proInfoOnLoad"
          @page-size-change="proInfoOnLoad"
          @page-refresh-change="proInfoOnLoad"
          @row-view="proInfoEdit"
          @row-remove="proInfoRemove"
          @row-edit="proInfoEdit"
        >
        </grid-layout>
      </el-tab-pane>
    </el-tabs>
    <!--  供应商联系信息 弹框  -->
    <el-dialog
      v-dialog-drag
      title="供应商联系信息"
      :modal="false"
      :modal-append-to-body="false"
      :visible.sync="conInfoDialog"
      v-if="conInfoDialog"
      width="60%"
    >
      <avue-form
        :option="conInfoDialogOption"
        ref="conInfoDataForm"
        v-model="conInfoRowData"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="conInfoDialog = false">{{
          $t(`cip.cmn.btn.celBtn`)
        }}</el-button>
        <el-button size="small" type="primary" @click="conInfoSave">{{
          $t(`cip.cmn.btn.defBtn`)
        }}</el-button>
      </span>
    </el-dialog>
    <!--  供应商资质信息 弹框  -->
    <el-dialog
      v-dialog-drag
      title="供应商资质信息"
      :modal="false"
      :modal-append-to-body="false"
      :visible.sync="qualInfoDialog"
      v-if="qualInfoDialog"
      width="60%"
    >
      <avue-form
        :option="qualInfoDialogOption"
        ref="qualInfoDataForm"
        v-model="qualInfoRowData"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="qualInfoDialog = false">{{
          $t(`cip.cmn.btn.celBtn`)
        }}</el-button>
        <el-button size="small" type="primary" @click="qualInfoSave">{{
          $t(`cip.cmn.btn.defBtn`)
        }}</el-button>
      </span>
    </el-dialog>
    <!--  供应商财务情况 弹框  -->
    <el-dialog
      v-dialog-drag
      title="供应商财务情况"
      :modal="false"
      :modal-append-to-body="false"
      :visible.sync="finInfoDialog"
      v-if="finInfoDialog"
      width="60%"
    >
      <avue-form
        :option="finInfoDialogOption"
        ref="finInfoDataForm"
        v-model="finInfoRowData"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="finInfoDialog = false">{{
          $t(`cip.cmn.btn.celBtn`)
        }}</el-button>
        <el-button size="small" type="primary" @click="finInfoSave">{{
          $t(`cip.cmn.btn.defBtn`)
        }}</el-button>
      </span>
    </el-dialog>
    <!--  供应商收款信息 弹框  -->
    <el-dialog
      v-dialog-drag
      title="供应商收款信息"
      :modal="false"
      :modal-append-to-body="false"
      :visible.sync="bankInfoDialog"
      v-if="bankInfoDialog"
      width="60%"
    >
      <avue-form
        :option="bankInfoDialogOption"
        ref="bankInfoDataForm"
        v-model="bankInfoRowData"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="bankInfoDialog = false">{{
          $t(`cip.cmn.btn.celBtn`)
        }}</el-button>
        <el-button size="small" type="primary" @click="bankInfoSave">{{
          $t(`cip.cmn.btn.defBtn`)
        }}</el-button>
      </span>
    </el-dialog>
    <!--  供应商产品目录 弹框  -->
    <el-dialog
      v-dialog-drag
      title="供应商产品目录"
      :modal="false"
      :modal-append-to-body="false"
      :visible.sync="proInfoDialog"
      v-if="proInfoDialog"
      width="60%"
    >
      <avue-form
        :option="proInfoDialogOption"
        ref="proInfoDataForm"
        v-model="proInfoRowData"
      ></avue-form>
      <span slot="footer">
        <el-button size="small" @click="proInfoDialog = false">{{
          $t(`cip.cmn.btn.celBtn`)
        }}</el-button>
        <el-button size="small" type="primary" @click="proInfoSave">{{
          $t(`cip.cmn.btn.defBtn`)
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CommonDialog from "@/components/CommonDialog";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import { getList, submit } from "@/api/sync/supplier";
import {
  conInfoSubmit, // 供应商联系信息
  conInfoRemove,
  qualInfoSubmit, //供应商资质信息
  qualInfoRemove,
  finInfoSubmit, //供应商财务情况
  finInfoRemove,
  bankInfoSubmit, //供应商收款信息
  bankInfoRemove,
  proInfoSubmit, //供应商产品目录
  proInfoRemove,
} from "@/api/sync/supplierSubset";

export default {
  components: {
    FormLayout,
    HeadLayout,
    GridLayout,
    CommonDialog,
  },
  data() {
    return {
      // 公共数据
      type: "view",
      //   readonly: true,
      activeName: "conInfo",
      // 供应商管理信息
      dataForm: {},

      // 111111111供应商联系信息
      conInfoData: [],
      conInfoSelectionList: [],
      // 111111111供应商联系信息
      conInfotRowData: {},
      conInfoDialog: false,
      conInfoTableLoading: false,
      conInfoPageTotal: 0,
      conInfoPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 111111111供应商联系信息
      conInfoTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id", //改
        rowParentKey: "code",
        menuWidth: 120,
        column: [
          {
            label: "联系人",
            prop: "contactPerson",
            cell: true,
            overHidden: true,
          },
          {
            label: "职位",
            prop: "contactLevel",
            cell: true,
            overHidden: true,
          },
          {
            label: "联系人传真",
            prop: "ntactFax",
            cell: true,
            overHidden: true,
          },
          {
            label: "手机号",
            prop: "contactPhone",
            cell: true,
            overHidden: true,
          },
          {
            label: "电话",
            prop: "contactTel",
            cell: true,
            overHidden: true,
          },
          {
            label: "邮箱",
            prop: "contactEmail",
            cell: true,
            overHidden: true,
          },
        ],
      },
      // 222222222供应商资质信息
      qualInfoData: [],
      qualInfoSelectionList: [],
      // 222222222供应商资质信息
      qualInfoRowData: {},
      qualInfoDialog: false,
      qualInfoTableLoading: false,
      qualInfoPageTotal: 0,
      qualInfoPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 222222222供应商资质信息
      qualInfoTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        rowParentKey: "code",
        menuWidth: 120,
        column: [
          {
            label: "资质类型名称",
            prop: "qualTypeName",
            cell: true,
            overHidden: true,
          },
          {
            label: "资质类型编码",
            prop: "qualTypeCode",
            cell: true,
            overHidden: true,
          },
          {
            label: "资质类型取值",
            prop: "qualTypeVals",
            cell: true,
            overHidden: true,
          },
          {
            label: "发证机关",
            prop: "issueAuth",
            cell: true,
            overHidden: true,
          },
          {
            label: "资质编号",
            prop: "qualCode",
            cell: true,
            overHidden: true,
          },
          {
            label: "开始日期",
            prop: "startDate",
            align: "center",
            cell: true,
            overHidden: true,
            width: 110,
          },
          {
            label: "截止日期",
            prop: "endDate",
            align: "center",
            span: 16,
            cell: true,
            overHidden: true,
            width: 110,
          },
          {
            label: "附件名称",
            prop: "fileName",
            span: 16,
            cell: true,
            overHidden: true,
          },
          {
            label: "附件地址",
            prop: "fileUrl",
            span: 16,
            cell: true,
            overHidden: true,
          },
          {
            label: "附件大小",
            prop: "fileSize",
            align: "center",
            cell: true,
            overHidden: true,
            width: 90,
          },
        ],
      },
      // 333333333供应商财务情况
      finInfoData: [],
      finInfoSelectionList: [],
      // 333333333供应商财务情况
      finInfoRowData: {},
      finInfoDialog: false,
      finInfoTableLoading: false,
      finInfoPageTotal: 0,
      finInfoPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 333333333供应商财务情况
      finInfoTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        rowParentKey: "code",
        menuWidth: 120,
        column: [
          {
            label: "财务数据年度",
            prop: "finDateYear",
            cell: true,
            overHidden: true,
          },
          {
            label: "总资产",
            prop: "totalAssert",
            cell: true,
            overHidden: true,
          },
          {
            label: "主营业务收入",
            prop: "mainIncome",
            cell: true,
            overHidden: true,
          },
          {
            label: "经营活动产生的现金流量净额",
            prop: "dcfMoney",
            cell: true,
            overHidden: true,
            width: 250,
          },
          {
            label: "应收账款",
            prop: "ar",
            cell: true,
            overHidden: true,
          },
          {
            label: "净利润率",
            prop: "npm",
            cell: true,
            overHidden: true,
          },
          {
            label: "资产负债率",
            prop: "alr",
            cell: true,
            overHidden: true,
          },
        ],
      },
      // 444444444供应商收款信息
      bankInfoData: [],
      bankInfoSelectionList: [],
      // 444444444供应商收款信息
      bankInfoRowData: {},
      bankInfoDialog: false,
      bankInfoTableLoading: false,
      bankInfoPageTotal: 0,
      bankInfoPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 444444444供应商收款信息
      bankInfoTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        rowParentKey: "code",
        menuWidth: 120,
        column: [
          {
            label: "账户名称",
            prop: "bankAccountName",
            cell: true,
            overHidden: true,
            width: 90,
          },
          {
            label: "银行账号",
            prop: "accountBankName",
            cell: true,
            overHidden: true,
          },
          {
            label: "开户行联行号",
            prop: "accountBankNum",
            cell: true,
            overHidden: true,
          },
          {
            label: "开户行地址",
            prop: "accountBankAddr",
            cell: true,
            overHidden: true,
          },
          {
            label: "swift code",
            prop: "swiftCode",
            cell: true,
            overHidden: true,
          },
          {
            label: "是否基本账户",
            prop: "isBasicAccount",
            align: "center",
            width: 120,
            cell: true,
            overHidden: true,
          },
          {
            label: "类型编码",
            prop: "accountTypeCode",
            width: 90,
            cell: true,
            overHidden: true,
          },
          {
            label: "类型名称",
            prop: "accountTypeName",
            width: 90,
            cell: true,
            overHidden: true,
          },
          // {
          //   label: "账户类型取值",
          //   prop: "accountTypeVals",
          //   span: 16,
          //   cell: true,
          //   overHidden: true,
          // },
          {
            label: "服务企业编码",
            prop: "serviceCode",
            width: 120,
            cell: true,
            overHidden: true,
          },
          {
            label: "服务企业名称",
            prop: "serviceName",
            width: 120,
            cell: true,
            overHidden: true,
          },
        ],
      },
      // 555555555供应商产品目录
      proInfoData: [],
      proInfoSelectionList: [],
      // 555555555供应商产品目录
      proInfoRowData: {},
      proInfoDialog: false,
      proInfoTableLoading: false,
      proInfoPageTotal: 0,
      qproInfoPage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      // 555555555供应商产品目录
      proInfoTableOption: {
        customAddBtn: true,
        addBtn: false,
        editBtn: false,
        rowKey: "id",
        rowParentKey: "code",
        menuWidth: 120,
        column: [
          {
            label: "物资类别名称",
            prop: "catName",
            cell: true,
            overHidden: true,
          },
          {
            label: "物资类别编码",
            prop: "catCode",
            cell: true,
            overHidden: true,
          },
          {
            label: "经营模式名称",
            prop: "modelName",
            cell: true,
            overHidden: true,
          },
          {
            label: "经营模式取值",
            prop: "modelCode",
            cell: true,
            overHidden: true,
          },
          {
            label: "备注",
            prop: "proRemark",
            cell: true,
            overHidden: true,
          },
        ],
      },
    };
  },
  computed: {
    // 11111111供应商联系信息 删除
    conInfoIds() {
      let conInfoIds = [];
      this.conInfoSelectionList.forEach((ele) => {
        conInfoIds.push(ele.id);
      });
      return conInfoIds;
      // .join(",");
    },
    // 22222222供应商资质信息 删除
    qualInfoIds() {
      let qualInfoIds = [];
      this.qualInfoSelectionList.forEach((ele) => {
        qualInfoIds.push(ele.id);
      });
      return qualInfoIds;
    },
    // 333333333供应商财务情况 删除
    finInfoIds() {
      let finInfoIds = [];
      this.finInfoSelectionList.forEach((ele) => {
        finInfoIds.push(ele.id);
      });
      return finInfoIds;
    },
    // 444444444供应商收款信息 删除
    bankInfoIds() {
      let bankInfoIds = [];
      this.bankInfoSelectionList.forEach((ele) => {
        bankInfoIds.push(ele.id);
      });
      return bankInfoIds;
    },
    // 555555555供应商产品目录 删除
    proInfoIds() {
      let proInfoIds = [];
      this.proInfoSelectionList.forEach((ele) => {
        proInfoIds.push(ele.id);
      });
      return proInfoIds;
    },

    // 供应商管理 新增的保存、保存并返回、取消
    headBtnOptions() {
      let result = [];
      if (["add", "edit"].includes(this.type)) {
        result.push({
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          btnOptType: "save",
        });
        result.push({
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-save-cancel",
        });
      }
      result.push({
        label: this.$t("cip.cmn.btn.celBtn"),
        emit: "head-cancel",
      });
      return result;
    },
    // 供应商管理 新增展现数据
    formColumn() {
      return [
        {
          label: "公司名称",
          prop: "name",
          span: 8,
          labelWidth: 125,
          maxlength: 200,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + "公司名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "单位简称",
          prop: "shortName",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "英文全称",
          prop: "enName",
          span: 8,
          labelWidth: 125,
          showWordLimit: true,
          disabled: !["add", "edit"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validateEnName,
              trigger: "blur",
            },
          ],
        },
        {
          label: "供应商编号",
          prop: "code",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["add"].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + "供应商编号",
              trigger: "blur",
            },
          ],
        },

        {
          label: "是否中建材集团",
          prop: "groupAround",
          span: 8,
          labelWidth: 125,
          type: "select",
          dicData: [
            { label: "否", value: "0" },
            { label: "是", value: "1" },
          ],
          props: {
            label: "label",
            value: "value",
          },
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + "是否中建材集团",
              trigger: "change",
            },
          ],
        },
        {
          label: "境内外",
          prop: "projectArea",
          type: "select",
          span: 8,
          labelWidth: 125,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=project_area",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t("cip.cmn.rule.inputWarning") + "境内外",
              trigger: "change",
            },
          ],
        },
        {
          label: "分发类型",
          prop: "disType",
          type: "select",
          span: 8,
          labelWidth: 125,
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=dis_type",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          rules: [
            {
              required: true,
              message:
                this.$t("cip.cmn.rule.selectWarning") +
                this.$t("cip.plat.sync.project.field.disType"),
              trigger: "change",
            },
          ],
        },
        {
          label: "社会信用代码",
          prop: "creditCode",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "税号",
          prop: "taxpayerIdNum",
          span: 8,
          labelWidth: 125,
          maxlength: 120,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "所属集团",
          prop: "groupName",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "供应商类型名称",
          prop: "kindName",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "供应商状态名称",
          prop: "supStatusName",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "所属集团编码",
          prop: "groupCode",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "供应商类型编码",
          prop: "kindCode",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "供应商状态编码",
          prop: "supStatusCode",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "洲",
          prop: "regionCode",
          span: 8,
          labelWidth: 125,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          cascader: ["countryCode"],
          dicUrl: "/api/sinoma-system/region/select?code=10",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "国家",
          prop: "countryCode",
          span: 8,
          labelWidth: 125,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          cascader: ["provinceCode"],
          dicUrl: "/api/sinoma-system/region/select?code={{regionCode}}",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "官方网站",
          prop: "web",
          span: 8,
          labelWidth: 125,
          maxlength: 100,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "省份/直辖市",
          prop: "provinceCode",
          span: 8,
          labelWidth: 125,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          cascader: ["cityCode"],
          dicUrl: "/api/sinoma-system/region/select?code={{countryCode}}",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "城市",
          prop: "cityCode",
          span: 8,
          labelWidth: 125,
          type: "select",
          props: {
            label: "name",
            value: "code",
          },
          cascader: ["countyCode"],
          dicUrl: "/api/sinoma-system/region/select?code={{provinceCode}}",
          disabled: !["add", "edit"].includes(this.type),
        },
        {
          label: "成立日期",
          prop: "buildDate",
          span: 8,
          labelWidth: 125,
          type: "date",
          valueFormat: "yyyy/MM/dd",
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "单位性质名称",
          prop: "typeName",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "法人代表",
          prop: "legalPerson",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "税务资质名称",
          prop: "taxTypeName",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "邮政编码",
          prop: "postCode",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "法人身份证",
          prop: "idCard",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "税务资质编码",
          prop: "taxTypeCode",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "采购采购商标识",
          prop: "isSup",
          span: 8,
          labelWidth: 125,
          type: "select",
          dicData: [
            { label: "否", value: "0" },
            { label: "是", value: "1" },
          ],
          props: {
            label: "label",
            value: "value",
          },
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "联系电话",
          prop: "tel",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
          rules: [
            {
              required: false,
              validator: this.validatePhone,
              trigger: "blur",
            },
          ],
        },
        {
          label: "准入时间",
          prop: "admissionDate",
          span: 8,
          labelWidth: 125,
          type: "date",
          valueFormat: "yyyy/MM/dd",
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "注册资本",
          prop: "money",
          span: 8,
          labelWidth: 125,
          maxlength: 50,
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "公司地址",
          prop: "address",
          span: 16,
          labelWidth: 125,
          maxlength: 100,
          //   type: "textarea",
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "供应商简介",
          prop: "supInt",
          span: 24,
          labelWidth: 125,
          maxlength: 500,
          type: "textarea",
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "主要产品及服务",
          prop: "mainServices",
          span: 24,
          labelWidth: 125,
          maxlength: 500,
          type: "textarea",
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
        {
          label: "备注",
          prop: "remark",
          span: 24,
          labelWidth: 125,
          maxlength: 200,
          type: "textarea",
          showWordLimit: true,
          disabled: !["edit", "add"].includes(this.type),
        },
      ];
    },

    // 111111111供应商联系信息 头按钮
    conInfoHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn;
    },
    // 111111111供应商联系信息 行按钮
    conInfoRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    // 111111111供应商联系信息 弹窗
    conInfoDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "联系人",
            prop: "contactPerson",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            // maxlength: 50,
            // showWordLimit: true,
          },
          {
            label: "职位",
            prop: "contactLevel",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "联系人传真",
            prop: "ntactFax",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "手机号",
            prop: "contactPhone",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            rules: [
              {
                required: true,
                validator: this.validatePhone,
                trigger: "blur",
              },
            ],
          },
          {
            label: "电话",
            prop: "contactTel",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
          },
          {
            label: "邮箱",
            prop: "contactEmail",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
          },
        ],
      };
    },

    // 222222222供应商资质信息 头按钮
    qualInfoHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn;
    },
    // 222222222供应商资质信息 行按钮
    qualInfoRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    // 222222222供应商资质信息 弹窗
    qualInfoDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "资质类型名称",
            prop: "qualTypeName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "资质类型名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "资质类型编码",
            prop: "qualTypeCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "资质类型编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "资质类型取值",
            prop: "qualTypeVals",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "发证机关",
            prop: "issueAuth",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "发证机关",
                trigger: "blur",
              },
            ],
          },
          {
            label: "资质编号",
            prop: "qualCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "资质编号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开始日期",
            prop: "startDate",
            type: "date",
            valueFormat: "yyyy/MM/dd",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
          {
            label: "截止日期",
            prop: "endDate",
            type: "date",
            valueFormat: "yyyy/MM/dd",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
          {
            label: "附件名称",
            prop: "fileName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "附件地址",
            prop: "fileUrl",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 200,
            showWordLimit: true,
          },
          {
            label: "附件大小",
            prop: "fileSize",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
        ],
      };
    },

    // 333333333供应商财务情况 头按钮
    finInfoHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn;
    },
    // 333333333供应商财务情况 行按钮
    finInfoRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    // 333333333供应商财务情况 弹窗
    finInfoDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "财务数据年度",
            prop: "finDateYear",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "财务数据年度",
                trigger: "blur",
              },
            ],
          },
          {
            label: "总资产",
            prop: "totalAssert",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + "资质类型编码",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "主营业务收入",
            prop: "mainIncome",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "经营活动产生的现金流量净额",
            prop: "dcfMoney",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "应收账款",
            prop: "ar",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "应收账款",
                trigger: "blur",
              },
            ],
          },
          {
            label: "净利润率",
            prop: "npm",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
          {
            label: "资产负债率",
            prop: "alr",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
        ],
      };
    },

    // 444444444供应商收款信息 头按钮
    bankInfoHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn;
    },
    // 444444444供应商收款信息 行按钮
    bankInfoRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    // 444444444供应商收款信息 弹窗
    bankInfoDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "账户名称",
            prop: "bankAccountName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "账户名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "银行账号",
            prop: "accountBankName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "银行账号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开户行联行号",
            prop: "accountBankNum",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "开户行地址",
            prop: "accountBankAddr",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "开户行地址",
                trigger: "blur",
              },
            ],
          },
          {
            label: "swift code",
            prop: "swiftCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            // rules: [
            //   {
            //     required: true,
            //     message: this.$t("cip.cmn.rule.inputWarning") + "资质编号",
            //     trigger: "blur",
            //   },
            // ],
          },
          {
            label: "是否基本账户",
            prop: "isBasicAccount",
            cell: true,
            type: "select",
            // dataType: "number",
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
            dicData: [
              { label: "否", value: "否" },
              { label: "是", value: "是" },
            ],
            props: {
              label: "label",
              value: "value",
            },
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "是否基本账户",
                trigger: "blur",
              },
            ],
          },
          {
            label: "账户类型编码",
            prop: "accountTypeCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
          {
            label: "账户类型名称",
            prop: "accountTypeName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          // {
          //   label: "账户类型取值",
          //   prop: "accountTypeVals",
          //   cell: true,
          //   disabled: !["edit", "add"].includes(this.type),
          //   maxlength: 200,
          //   showWordLimit: true,
          // },
          {
            label: "服务企业编码",
            prop: "serviceCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
          {
            label: "服务企业名称",
            prop: "serviceName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
        ],
      };
    },

    // 555555555供应商产品目录 头按钮
    proInfoHeadBtnOptions() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "新增",
          emit: "head-add-tabs",
          type: "button",
          icon: "",
          btnOptType: "add",
        });
        buttonBtn.push({
          label: "删除",
          emit: "head-remove",
          type: "button",
          icon: "",
          btnOptType: "remove",
        });
      }
      return buttonBtn;
    },
    // 555555555供应商产品目录 行按钮
    proInfoRowBtn() {
      let buttonBtn = [];
      if (["add", "edit"].includes(this.type)) {
        buttonBtn.push({
          label: "编辑",
          emit: "row-edit",
          type: "button",
          icon: "",
        });
        buttonBtn.push({
          label: "删除",
          emit: "row-remove",
          type: "button",
          icon: "",
        });
      }
      if (["view"].includes(this.type)) {
        buttonBtn.push({
          label: "查看",
          emit: "row-view",
          type: "button",
          icon: "",
        });
      }
      return buttonBtn;
    },
    // 555555555供应商产品目录 弹窗
    proInfoDialogOption() {
      return {
        size: "small",
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 130,
        column: [
          {
            label: "物资类别名称",
            prop: "catName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "物资类别名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "物资类别编码",
            prop: "catCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + "物资类别编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "经营模式名称",
            prop: "modelName",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "经营模式取值",
            prop: "modelCode",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            maxlength: 50,
            showWordLimit: true,
          },
          {
            label: "备注",
            prop: "proRemark",
            span: 24,
            maxlength: 200,
            type: "textarea",
            cell: true,
            disabled: !["edit", "add"].includes(this.type),
            showWordLimit: true,
          },
        ],
      };
    },
  },

  created() {
    let { data, type } = this.$route.query;
    this.type = type;
    if (this.type !== "add") {
      this.dataForm = data;
    }
  },
  mounted() {
    if (this.type !== "add") {
      this.conInfoOnLoad();
    }
  },
  methods: {
    // 保存 供应商管理 的数据
    headSave(cancel = false) {
      this.dataForm = this.$refs.formLayout.form.form;
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          submit(this.dataForm)
            .then((res) => {
              const { msg, data } = res.data;
              if (res.data.code === 200) {
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.headCancel();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data);
                this.type = "edit";
                this.created();
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            });
        }
      });
    },
    // 供应商管理 返回
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

    //切换附表
    handleClick() {
      if (!this.dataForm.code) {
        this.$message.warning("请先保存头数据");
        return;
      }
      if (this.activeName === "qualInfo") {
        //供应商资质信息
        this.qualInfoOnLoad();
      } else if (this.activeName === "finInfo") {
        //供应商财务情况
        this.finInfoOnLoad();
      } else if (this.activeName === "bankInfo") {
        //供应商收款信息
        this.bankInfoOnLoad();
      } else if (this.activeName === "proInfo") {
        //供应商产品目录
        this.proInfoOnLoad();
      } else {
        this.conInfoOnLoad();
      }
    },
    // 111111111
    // 供应商联系信息 获取数据
    conInfoOnLoad(conInfoPage, params = {}) {
      this.conInfoPage = this.$refs.conInfoCrud.page;
      this.conInfoTableLoading = true;
      params.code = this.dataForm.code;
      getList(
        this.conInfoPage.currentPage,
        this.conInfoPage.pageSize,
        params
      ).then((res) => {
        const data = res.data.data;
        this.conInfoPage.total = data.total;
        this.conInfoPageTotal = data.total;
        const exist1 = data.records[0].coninfo.filter(
          (item) => item.status == 1
        );
        this.conInfoData = exist1;
        this.conInfoTableLoading = false;
        this.conInfoSelectionList = [];
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.conInfoCrud.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 供应商联系信息 打开/新增弹框
    conInfoTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据");
        return;
      }
      this.conInfoRowData = {};
      this.conInfoDialog = true;
    },
    // 供应商联系信息 数据修改或查看
    conInfoEdit(row) {
      this.fullscreen = false;
      this.conInfoDialog = true;
      this.conInfoRowData = row;
    },
    // 供应商联系信息 批量删除
    conInfoHeadRemove() {
      if (this.conInfoSelectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        conInfoRemove(this.conInfoIds).then((res) => {
          if (res.data.code === 200) {
            this.conInfoOnLoad();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    // 供应商联系信息 行删除
    conInfoRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return conInfoRemove(row.id);
        })
        .then(() => {
          this.conInfoOnLoad();
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 供应商联系信息 保存数据
    conInfoSave() {
      this.$refs.conInfoDataForm.validate((valid) => {
        if (valid) {
          this.conInfoRowData.code = this.dataForm.code;
          conInfoSubmit(this.conInfoRowData).then((res) => {
            this.conInfoOnLoad();
            if (res.data.code === 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(res.data.msg);
            }
            this.conInfoDialog = false;
          });
        }
      });
    },
    // 供应商联系信息 选项变化
    conInfoSelectionChange(list) {
      this.conInfoSelectionList = list;
    },
    // 111111111

    // 222222222
    // 供应商资质信息 获取数据
    qualInfoOnLoad(qualInfoPage, params = {}) {
      this.qualInfoPage = this.$refs.qualInfoCrud.page;
      this.qualInfoTableLoading = true;
      params.code = this.dataForm.code;
      getList(
        this.qualInfoPage.currentPage,
        this.qualInfoPage.pageSize,
        params
      ).then((res) => {
        const data = res.data.data;
        this.qualInfoPage.total = data.total;
        this.qualInfoPageTotal = data.total;
        const exist2 = data.records[0].qualinfo.filter(
          (item) => item.status == 1
        );
        this.qualInfoData = exist2;
        this.qualInfoTableLoading = false;
        this.qualInfoSelectionList = [];
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.qualInfoCrud.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 供应商资质信息 打开/新增弹框
    qualInfoTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据");
        return;
      }
      this.qualInfoRowData = {};
      this.qualInfoDialog = true;
    },
    // 供应商资质信息 数据修改或查看
    qualInfoEdit(row) {
      this.fullscreen = false;
      this.qualInfoDialog = true;
      this.qualInfoRowData = row;
    },
    // 供应商资质信息 批量删除
    qualInfoHeadRemove() {
      if (this.qualInfoSelectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        qualInfoRemove(this.qualInfoIds).then((res) => {
          if (res.data.code === 200) {
            this.qualInfoOnLoad();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    // 供应商资质信息 行删除
    qualInfoRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return qualInfoRemove(row.id);
        })
        .then(() => {
          this.qualInfoOnLoad();
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 供应商资质信息 保存数据
    qualInfoSave() {
      this.$refs.qualInfoDataForm.validate((valid) => {
        if (valid) {
          this.qualInfoRowData.code = this.dataForm.code;
          qualInfoSubmit(this.qualInfoRowData).then((res) => {
            this.qualInfoOnLoad();
            if (res.data.code === 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(res.data.msg);
            }
            this.qualInfoDialog = false;
          });
        }
      });
    },
    // 供应商资质信息 选项变化
    qualInfoSelectionChange(list) {
      this.qualInfoSelectionList = list;
    },
    // 222222222

    // 333333333
    // 供应商财务情况 获取数据
    finInfoOnLoad(finInfoPage, params = {}) {
      this.finInfoPage = this.$refs.finInfoCrud.page;
      this.finInfoTableLoading = true;
      params.code = this.dataForm.code;
      getList(
        this.finInfoPage.currentPage,
        this.finInfoPage.pageSize,
        params
      ).then((res) => {
        const data = res.data.data;
        this.finInfoPage.total = data.total;
        this.finInfoPageTotal = data.total;
        const exist3 = data.records[0].fininfo.filter(
          (item) => item.status == 1
        );
        this.finInfoData = exist3;
        this.finInfoTableLoading = false;
        this.finInfoSelectionList = [];
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.finInfoCrud.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 供应商财务情况 打开/新增弹框
    finInfoTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据");
        return;
      }
      this.finInfoRowData = {};
      this.finInfoDialog = true;
    },
    // 供应商财务情况 数据修改或查看
    finInfoEdit(row) {
      this.fullscreen = false;
      this.finInfoDialog = true;
      this.finInfoRowData = row;
    },
    // 供应商财务情况 批量删除
    finInfoHeadRemove() {
      if (this.finInfoSelectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        finInfoRemove(this.finInfoIds).then((res) => {
          if (res.data.code === 200) {
            this.finInfoOnLoad();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    // 供应商财务情况 行删除
    finInfoRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return finInfoRemove(row.id);
        })
        .then(() => {
          this.finInfoOnLoad();
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 供应商财务情况 保存数据
    finInfoSave() {
      this.$refs.finInfoDataForm.validate((valid) => {
        if (valid) {
          this.finInfoRowData.code = this.dataForm.code;
          console.log(
            this.dataForm.code,
            this.finInfoRowData.id,
            "this.dataForm.code"
          );
          this.finInfoRowData.id = this.finInfoRowData.id;
          this.finInfoRowData.status = "1";
          finInfoSubmit(this.finInfoRowData).then((res) => {
            this.finInfoOnLoad();
            if (res.data.code === 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(res.data.msg);
            }
            this.finInfoDialog = false;
          });
        }
      });
    },
    // 供应商财务情况 选项变化
    finInfoSelectionChange(list) {
      this.finInfoSelectionList = list;
    },
    // 333333333

    // 444444444
    // 供应商收款信息 获取数据
    bankInfoOnLoad(bankInfoPage, params = {}) {
      this.bankInfoPage = this.$refs.bankInfoCrud.page;
      this.bankInfoTableLoading = true;
      params.code = this.dataForm.code;
      getList(
        this.bankInfoPage.currentPage,
        this.bankInfoPage.pageSize,
        params
      ).then((res) => {
        const data = res.data.data;
        this.bankInfoPage.total = data.total;
        this.bankInfoPageTotal = data.total;
        const exist4 = data.records[0].bankinfo.filter(
          (item) => item.status == 1
        );
        this.bankInfoData = exist4;
        this.bankInfoTableLoading = false;
        this.bankInfoSelectionList = [];
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.bankInfoCrud.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 供应商收款信息 打开/新增弹框
    bankInfoTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据");
        return;
      }
      this.bankInfoRowData = {};
      this.bankInfoDialog = true;
    },
    // 供应商收款信息 数据修改或查看
    bankInfoEdit(row) {
      this.fullscreen = false;
      this.bankInfoDialog = true;
      this.bankInfoRowData = row;
    },
    // 供应商收款信息 批量删除
    bankInfoHeadRemove() {
      if (this.bankInfoSelectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        bankInfoRemove(this.bankInfoIds).then((res) => {
          if (res.data.code === 200) {
            this.bankInfoOnLoad();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    // 供应商收款信息 行删除
    bankInfoRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return bankInfoRemove(row.id);
        })
        .then(() => {
          this.bankInfoOnLoad();
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 供应商收款信息 保存数据
    bankInfoSave() {
      this.$refs.bankInfoDataForm.validate((valid) => {
        if (valid) {
          this.bankInfoRowData.code = this.dataForm.code;
          bankInfoSubmit(this.bankInfoRowData).then((res) => {
            this.bankInfoOnLoad();
            if (res.data.code === 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(res.data.msg);
            }
            this.bankInfoDialog = false;
          });
        }
      });
    },
    // 供应商收款信息 选项变化
    bankInfoSelectionChange(list) {
      this.bankInfoSelectionList = list;
    },
    // 444444444

    // 555555555
    // 供应商产品目录 获取数据
    proInfoOnLoad(proInfoPage, params = {}) {
      this.proInfoPage = this.$refs.proInfoCrud.page;
      this.proInfoTableLoading = true;
      params.code = this.dataForm.code;
      getList(
        this.proInfoPage.currentPage,
        this.proInfoPage.pageSize,
        params
      ).then((res) => {
        const data = res.data.data;
        this.proInfoPage.total = data.total;
        this.proInfoPageTotal = data.total;
        const exist5 = data.records[0].proinfo.filter(
          (item) => item.status == 1
        );
        this.proInfoData = exist5;
        this.proInfoTableLoading = false;
        this.proInfoSelectionList = [];
        //重新渲染表格，解决勾选框错行问题
        this.$nextTick(() => {
          this.$refs.proInfoCrud.$refs.grid.$refs.table.doLayout();
        });
      });
    },
    // 供应商产品目录 打开/新增弹框
    proInfoTableAdd() {
      if (!this.dataForm.id) {
        this.$message.warning("请先保存头数据");
        return;
      }
      this.proInfoRowData = {};
      this.proInfoDialog = true;
    },
    // 供应商产品目录 数据修改或查看
    proInfoEdit(row) {
      this.fullscreen = false;
      this.proInfoDialog = true;
      this.proInfoRowData = row;
    },
    // 供应商产品目录 批量删除
    proInfoHeadRemove() {
      if (this.proInfoSelectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.chooseAtLeastOne"));
        return;
      }
      this.$confirm(this.$t("cip.cmn.msg.warning.determineDelWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      }).then(() => {
        proInfoRemove(this.proInfoIds).then((res) => {
          if (res.data.code === 200) {
            this.proInfoOnLoad();
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
          }
        });
      });
    },
    // 供应商产品目录 行删除
    proInfoRemove(row) {
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return proInfoRemove(row.id);
        })
        .then(() => {
          this.proInfoOnLoad();
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    // 供应商产品目录 保存数据
    proInfoSave() {
      this.$refs.proInfoDataForm.validate((valid) => {
        if (valid) {
          this.proInfoRowData.code = this.dataForm.code;
          proInfoSubmit(this.proInfoRowData).then((res) => {
            this.proInfoOnLoad();
            if (res.data.code === 200) {
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
            } else {
              this.$message.success(res.data.msg);
            }
            this.proInfoDialog = false;
          });
        }
      });
    },
    // 供应商产品目录 选项变化
    proInfoSelectionChange(list) {
      this.proInfoSelectionList = list;
    },
    // 555555555

    // 英文全称校验
    validateEnName(rule, value, callback) {
      if (value !== "") {
        let isOfficePhone = /^[A-Za-z\s]+$/;
        if (isOfficePhone.test(value)) {
          callback();
        } else {
          callback(new Error("只能输入英文"));
        }
      } else {
        callback();
      }
    },
    // 手机号校验
    validatePhone(rule, value, callback) {
      if (value !== "") {
        let isPhone = /^1[3-9]\d{9}$/;
        if (value.length === 11) {
          if (isPhone.test(value)) {
            callback();
          } else {
            callback(new Error("联系电话格式不正确"));
          }
        } else {
          callback(new Error("请输入联系电话长度为11位数字"));
        }
      } else {
        callback();
      }
    },
  },
};
</script>

<style scoped>
</style>
